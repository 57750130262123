@use 'sass:map';

// Switch imported File to check the different versions
@use '@swisspost/design-system-styles/intranet';
@use '@swisspost/design-system-styles/core' as post;

html {
  scroll-behavior: smooth;
}

body {
  // Necessary to prevent horizontal scrollbar from the topic teaser padding
  overflow-x: hidden;
}

.pi-1000-warning {
  @include post.pi(1000, 'warning');
}

.pi-1000-primary {
  @include post.pi(1000, 'primary');
}

.pi-1000-success {
  @include post.pi(1000, 'success');
}
.pi-1000-error {
  @include post.pi(1000, 'error');
}

.pi-1014-white {
  @include post.pi(1014, 'white');
}

.pi-1000-white {
  @include post.pi(1000, 'white');
}

.pi-2062-warning {
  @include post.pi(2062, 'warning');
}

.pi-2062-white {
  @include post.pi(2062, 'white');
}

.pi-3192-white {
  @include post.pi(3192, 'white');
}

.pi-2104-white {
  @include post.pi(2104, 'white');
}

.code-sample {
  margin-top: 35px;
  text-align: center;
}

.code-sample h5 {
  margin-bottom: 25px;
}

.code-sample pre {
  padding: 24px;
  text-align: left;
  background-color: #f8f6f0;
}

img {
  max-width: 100%;
}

section {
  margin-bottom: map.get($map: post.$post-sizes, $key: 'bigger-big');
}

.mark-attention {
  color: #a51728;
  font-weight: bold;
  font-variant: small-caps;
  font-size: 60%;
  position: relative;
  top: -0.5rem;
}

code {
  padding: 0.1em 0.4em;
  margin: 0;
  font-size: 85%;
  background-color: var(--post-gray-10);
  border-radius: 6px;

  &.block,
  &.d-block,
  &.block.hljs,
  &.d-block.hljs {
    display: block;
    padding: post.$size-micro post.$size-mini;
    background: var(--post-gray-80);
    color: white;
    white-space: pre;
    max-width: 100%;
    overflow: auto;
    margin-bottom: post.$size-small-regular;
    max-height: 450px;
  }

  &[appCopyToClipboard] {
    position: relative;
    cursor: pointer;

    &:not(.hljs)::after {
      position: absolute;
      left: 100%;
      top: 50%;
      transform: scale(0.5) translateY(-50%);
      background-color: rgba(var(--post-bg-rgb), 0.95);
      padding: 0 post.$size-small-regular;
      transform-origin: top left;
      z-index: 2;
    }

    &.hljs::after {
      position: absolute;
      right: 0;
      top: 50%;
      transform: scale(0.75) translateY(-50%);
      padding: 0 post.$size-mini;
      transform-origin: top left;
    }

    &:not(.copied) {
      &:not(.hljs):hover {
        background-color: var(--post-gray-20);

        &::after {
          content: url(post.get-colored-svg-url(2012, post.$black));
        }
      }

      &.hljs:hover::after {
        content: url(post.get-colored-svg-url(2012, post.$white));
      }
    }

    &.copied::after {
      content: url(post.get-colored-svg-url(2105, post.$success));
    }
  }
}
